import { type Room } from 'livekit-client'
import { BroadcastState } from '../../types'
import type { MeetingCubit } from '../MeetingCubit'
import { BroadcastMonitor } from './BroadcastMonitor'
import { TranscriptController } from './TranscriptController'
import { DeviceManager } from '../../util/DeviceManager'
import z from 'zod'
import { captureException } from '@sentry/core'

/**
 * The MeetingLivekitController class is responsible for communicating with Livekit
 * and updating the Meeting model with the relavant data.
 *
 * The idea is to break up the Meeting class into smaller, more manageable pieces.
 */
export class MeetingLivekitController {
  unsubscribers: (() => void)[] = []
  transcriptController: TranscriptController
  // import { EventEmitter } from 'events'

  constructor(protected meeting: MeetingCubit) {
    this.transcriptController = new TranscriptController(meeting)
  }

  initialize() {
    this.setupBroadcastMonitor()
  }

  dispose() {
    for (const unsub of this.unsubscribers) {
      unsub()
    }
    this.livekitRoom?.removeAllListeners()
    this.broadcastMonitor?.detach()
  }

  onSlideChange() {
    this.setupBroadcastMonitor()
  }

  lastMessage: string = ''
  sendStreamStatus(status: string, position: number, duration: number) {
    if (!this.livekitRoom) return

    const slideId = this.meeting.currentSlide?.id

    if (!slideId) return

    const message = JSON.stringify({
      type: 6,
      status,
      duration: Math.round(duration),
      position: Math.round(position),
      identity: this.currentUser.uid,
      userId: this.currentUser.uid,
      slideId,
    })

    this.meeting.updateSlideStreamStatus(
      slideId,
      this.currentUser.uid,
      status,
      position,
      duration
    )

    if (this.lastMessage === message) return

    this.lastMessage = message

    const participantArray = Array.from(
      this.livekitRoom.remoteParticipants.values()
    )
    const groupLeaders = participantArray.filter((p) =>
      this.meeting.groupLeaderUserIds.includes(p.identity)
    )

    if (groupLeaders.length === 0) return

    this.livekitRoom.localParticipant?.publishData(
      new TextEncoder().encode(message),
      {
        reliable: true,
        destinationIdentities: groupLeaders.map((p) => p.identity),
      }
    )
  }

  sendBroadcastMessage(message: string) {
    // only admins and group leaders can send broadcast messages
    const canSend =
      this.currentUser.isAdmin || this.meeting.currentUserIsGroupLeader

    if (!canSend) return

    if (this.livekitRoom) {
      const participantArray = Array.from(
        this.livekitRoom.remoteParticipants.values()
      )
      const breakoutParticipant = participantArray.find(
        (p) => p.identity === 'Breakout Learning'
      )
      if (!breakoutParticipant) return

      if (message === 'pause') {
        this.meeting.setBroadcastState(BroadcastState.paused)
      } else if (message === 'stop') {
        this.meeting.setBroadcastState(BroadcastState.stopped)
      } else if (message === 'play') {
        this.meeting.setBroadcastState(BroadcastState.playing)
      }

      this.livekitRoom.localParticipant?.publishData(
        new TextEncoder().encode(message),
        {
          reliable: true,
          destinationIdentities: [breakoutParticipant.identity],
        }
      )
    }
  }

  sendParticipantMessage(participantUserId: string, message: 'muteMic') {
    // only admins and group leaders can send broadcast messages
    const canSend =
      this.currentUser.isAdmin || this.meeting.currentUserAllowedToMute

    if (!canSend) return

    if (!this.livekitRoom) return

    if (message === 'muteMic') {
      const payload = JSON.stringify({
        type: LivekitMessageType.muteMic,
      })
      this.livekitRoom.localParticipant?.publishData(
        new TextEncoder().encode(payload),
        {
          reliable: true,
          destinationIdentities: [participantUserId],
        }
      )
    }
  }

  updateMetadata() {
    if (!this.livekitRoom) return
    if (!this.livekitRoom.localParticipant) return
    const currentMetadata = this.livekitRoom.localParticipant.metadata || '{}'
    const parsed = JSON.parse(currentMetadata)
    parsed['info'] = 'test'
    const serialized = JSON.stringify(parsed)
    this.livekitRoom.localParticipant.setMetadata(serialized)
  }

  seekBroadcast(position: number) {
    if (this.livekitRoom) {
      this.meeting.setBroadcastPosition(position + 3)
      this.sendBroadcastMessage(`seek|${position + 3}`)
    }
  }

  setupLivekitListeners() {
    const room = this.livekitRoom
    if (!room) return

    room.on('mediaDevicesChanged', async () => {
      const devices = await DeviceManager.getLocalDevices()

      const activeAudioInput = room.getActiveDevice('audioinput')
      if (activeAudioInput) {
        const audioInputDevices = devices.filter((d) => d.kind === 'audioinput')
        const found = audioInputDevices.find(
          (d) => d.deviceId === activeAudioInput
        )
        if (!found && audioInputDevices.length > 0) {
          room.switchActiveDevice('audioinput', audioInputDevices[0].deviceId)
        }
        if (audioInputDevices.length === 0) {
          this.meeting.repository.logEvent('no_audio_input_devices')
        }
      }

      const activeAudioOutput = room.getActiveDevice('audiooutput')
      if (activeAudioOutput) {
        const audioOutputDevices = devices.filter(
          (d) => d.kind === 'audiooutput'
        )
        const found = audioOutputDevices.find(
          (d) => d.deviceId === activeAudioOutput
        )
        if (!found && audioOutputDevices.length > 0) {
          room.switchActiveDevice('audiooutput', audioOutputDevices[0].deviceId)
        }
        if (audioOutputDevices.length === 0) {
          this.meeting.repository.logEvent('no_audio_output_devices')
        }
      }

      const activeVideoInput = room.getActiveDevice('videoinput')
      if (activeVideoInput) {
        const videoInputDevices = devices.filter((d) => d.kind === 'videoinput')
        const found = videoInputDevices.find(
          (d) => d.deviceId === activeVideoInput
        )
        if (!found && videoInputDevices.length > 0) {
          room.switchActiveDevice('videoinput', videoInputDevices[0].deviceId)
        }
        if (videoInputDevices.length === 0) {
          this.meeting.repository.logEvent('no_video_output_devices')
        }
      }
    })

    room.on('trackMuted', (publication) => {
      this.meeting.logEvent('room_track_muted', {
        track_kind: publication.kind,
      })
    })
    room.on('trackUnmuted', (publication) => {
      this.meeting.logEvent('room_track_unmuted', {
        track_kind: publication.kind,
      })
    })
    room.on('trackSubscriptionFailed', async (trackId, participant, reason) => {
      console.error('trackSubscriptionFailed', reason)
    })
    room.on('activeSpeakersChanged', (speakers) => {
      const sorted = speakers.sort((a, b) =>
        b.audioLevel > a.audioLevel ? 1 : -1
      )
      const first = sorted[0]

      this.meeting.updateActiveSpeaker(first?.identity || null)
    })
    room.on('dataReceived', (data) => {
      const decoded = new TextDecoder().decode(data)
      if (decoded) {
        const { data, type } = this.parseLivekitMessage(decoded)
        if (type === LivekitMessageType.status) {
          const { status } = data
          if (status === LivekitStreamStatus.playing)
            this.meeting.setBroadcastState(BroadcastState.playing)
          if (status === LivekitStreamStatus.paused)
            this.meeting.setBroadcastState(BroadcastState.paused)
          if (status === LivekitStreamStatus.stopped)
            this.meeting.setBroadcastState(BroadcastState.stopped)
        } else if (type === LivekitMessageType.duration) {
          const { duration } = data
          this.meeting.setBroadcastDuration(duration)
          // duration
        } else if (type === LivekitMessageType.position) {
          // if we got duration/position on a non-video slide, we should stop the broadcast
          if (!this.meeting.currentSlide?.isVideoSlide) {
            console.error(
              'Broadcast detected on non-video slide, stopping broadcast'
            )
            this.sendBroadcastMessage('stop')
          }
          const { position, duration } = data
          this.meeting.setBroadcastState(BroadcastState.playing)
          this.meeting.setBroadcastPositionAndDuration(position, duration)
        } else if (type === LivekitMessageType.transcript) {
          this.transcriptController.handleMessage(data)
        } else if (type === LivekitMessageType.unknown) {
          // should not happen
          captureException(new Error('Received unknown livekit message type'))
        } else if (type === 6) {
          const { status, position, duration, userId, slideId } = data
          this.meeting.updateSlideStreamStatus(
            slideId,
            userId,
            getStreamStatusStringFromEnum(status),
            position,
            duration
          )
        } else if (type === LivekitMessageType.streamPause) {
          this.meeting.localCommands.emit('pauseVideo')
        } else if (type === LivekitMessageType.muteMic) {
          this.mute()
        }
      }
    })
    room.on('participantConnected', () => {
      this.updateParticipantIds()
      this.setupBroadcastMonitor()
    })
    room.on('participantDisconnected', () => {
      this.updateParticipantIds()
      this.setupBroadcastMonitor()
    })
    room.on('disconnected', () => {
      this.meeting.logEvent('room_disconnected')
    })
    room.on('reconnected', () => {
      this.meeting.logEvent('room_reconnected')
    })
    room.on('connected', () => {
      this.meeting.logEvent('room_connected')
      this.updateParticipantIds()
      this.setupBroadcastMonitor()

      const participants = Array.from(room.remoteParticipants.values())

      const breakoutParticipant = participants.find(
        (p) => p.identity === 'Breakout Learning'
      )

      if (breakoutParticipant) {
        // assume we are initialized, an event from the broadcast will update this
        this.meeting.setBroadcastState(BroadcastState.initialized)
      }
    })
  }

  updateParticipantIds() {
    if (!this.livekitRoom) return
    const participants = Array.from(
      this.livekitRoom.remoteParticipants.values()
    )
    const userIds = participants.map((p) => p.identity)

    this.meeting.updateParticipantIds(userIds)
  }

  mute() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    return this.livekitRoom?.localParticipant.setMicrophoneEnabled(false)
  }

  toggleAudio() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    const currentState = this.livekitRoom.localParticipant.isMicrophoneEnabled
    return this.livekitRoom?.localParticipant.setMicrophoneEnabled(
      !currentState
    )
  }

  unmute() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    return this.livekitRoom?.localParticipant.setMicrophoneEnabled(true)
  }

  isAudioEnabled() {
    return this.livekitRoom?.localParticipant.isMicrophoneEnabled
  }

  toggleVideo() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    const currentState = this.livekitRoom.localParticipant.isCameraEnabled
    return this.livekitRoom?.localParticipant.setCameraEnabled(!currentState)
  }

  toggleScreenShare() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    const currentState = this.livekitRoom.localParticipant.isScreenShareEnabled
    return this.livekitRoom?.localParticipant.setScreenShareEnabled(
      !currentState
    )
  }

  disableVideo() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    return this.livekitRoom?.localParticipant.setCameraEnabled(false)
  }

  enableVideo() {
    if (!this.livekitRoom) return Promise.resolve(undefined)
    return this.livekitRoom?.localParticipant.setCameraEnabled(true)
  }

  isVideoEnabled() {
    return this.livekitRoom?.localParticipant.isCameraEnabled
  }

  toggleVideoForParticipant(participantId: string) {
    const participant = this.livekitRoom?.remoteParticipants.get(participantId)
    if (!participant) return

    for (const publication of participant.trackPublications.values()) {
      if (publication.kind.toString() === 'video') {
        publication.setSubscribed(!publication.isSubscribed)
      }
    }
  }

  get livekitRoom(): Room | null {
    return this.meeting.livekitRoom
  }

  get currentUser() {
    return this.meeting.currentUser
  }

  broadcastMonitor: BroadcastMonitor | null = null

  setupBroadcastMonitor() {
    if (!this.livekitRoom) {
      return
    }
    const participants = Array.from(
      this.livekitRoom.remoteParticipants.values()
    )

    const breakoutParticipant = participants.find(
      (p) => p.identity === 'Breakout Learning'
    )

    // if the slide changed and we have a running broadcastMonitor,
    // we need to flush it and start a new one
    if (
      breakoutParticipant &&
      this.broadcastMonitor &&
      this.broadcastMonitor.monitoredSlide !== this.meeting.activeSlide
    ) {
      this.broadcastMonitor.flush()
      this.broadcastMonitor.detach()
      this.broadcastMonitor = new BroadcastMonitor(this.meeting)
      this.broadcastMonitor.attach()
    }
    if (breakoutParticipant && !this.broadcastMonitor) {
      this.broadcastMonitor = new BroadcastMonitor(this.meeting)
      this.broadcastMonitor.attach()
    } else if (!breakoutParticipant && this.broadcastMonitor) {
      this.broadcastMonitor.flush()
      this.broadcastMonitor.detach()
      this.broadcastMonitor = null
    }
  }

  private parseLivekitMessage(message: string) {
    const json = JSON.parse(message)
    const base = baseMessageSchema.parse(json)

    // i know it's redundant to return the type in the data prop as well as
    // the base.type, but it's the only way I could get the type inference
    // to work in the ifs/switches above
    switch (base.type) {
      case LivekitMessageType.status:
        return { data: statusMessageSchema.parse(json), type: base.type }
      case LivekitMessageType.duration:
        return { data: durationMessageSchema.parse(json), type: base.type }
      case LivekitMessageType.position:
        return { data: positionMessageSchema.parse(json), type: base.type }
      case LivekitMessageType.transcript:
        return { data: transcriptMessageSchema.parse(json), type: base.type }
      case LivekitMessageType.streamPosition:
        return {
          data: streamPositionMessageSchema.parse(json),
          type: base.type,
        }
    }
    return { data: undefined, type: base.type }
  }
}

enum LivekitMessageType {
  status = 0,
  duration = 1,
  position = 2,
  transcript = 3,
  unknown = 4,
  resubscribe = 5,
  streamPosition = 6,
  streamPause = 7,
  muteMic = 8,
}

enum LivekitStreamStatus {
  uninitialized = 0,
  initialized = 1,
  playing = 2,
  paused = 3,
  stopped = 4,
}

function getStreamStatusStringFromEnum(status: LivekitStreamStatus) {
  switch (status) {
    case LivekitStreamStatus.uninitialized:
      return 'uninitialized'
    case LivekitStreamStatus.initialized:
      return 'initialized'
    case LivekitStreamStatus.playing:
      return 'playing'
    case LivekitStreamStatus.paused:
      return 'paused'
    case LivekitStreamStatus.stopped:
      return 'stopped'
  }
}

const baseMessageSchema = z.object({
  type: z.nativeEnum(LivekitMessageType),
})

const statusMessageSchema = baseMessageSchema.extend({
  type: z.literal(LivekitMessageType.status),
  status: z.nativeEnum(LivekitStreamStatus),
})

const durationMessageSchema = baseMessageSchema.extend({
  type: z.literal(LivekitMessageType.duration),
  duration: z.number().int(),
})

const positionMessageSchema = baseMessageSchema.extend({
  type: z.literal(LivekitMessageType.position),
  position: z.number().int(),
  duration: z.number().int(),
})

const transcriptMessageSchema = baseMessageSchema.extend({
  type: z.literal(LivekitMessageType.transcript),
  transcript: z.string(),
  identity: z.string(),
  id: z.number().int(), // transcriptId
  final: z.boolean(),
})

const streamPositionMessageSchema = baseMessageSchema.extend({
  type: z.literal(LivekitMessageType.streamPosition),
  position: z.number().int(),
  duration: z.number().int(),
  slideId: z.string(),
  userId: z.string(),
  status: z.nativeEnum(LivekitStreamStatus),
})
