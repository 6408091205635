import type {
  DocumentData,
  DocumentReference,
  Query,
  QuerySnapshot,
  QueryDocumentSnapshot,
  DocumentSnapshot,
} from 'firebase/firestore'
import type { CollectionReference } from 'firebase/firestore'
import { onSnapshot } from 'firebase/firestore'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { MobxDocument } from '../types'
import { StreamController } from 'tricklejs'
import type { StreamInterface } from 'tricklejs/dist/types'
import type { StreamSubscriptionActions } from 'tricklejs/dist/stream_subscription'
import type { ObservableModel, ObservableModelClass } from './model'
import { captureException } from '@sentry/core'
import { WrappedFirestoreError } from './errors'

const CHROME_IE_STACK_REGEXP = /^\s*at .*(\S+:\d+|\(native\))/m

// This function removes the lines related to the new Error().stack trick
// we do to get around Firestore's insane error handling
function sanitizeStack(
  stack: string | undefined,
  options?: { wrapped: boolean }
) {
  if (!stack) return undefined
  const linesToRemove = options?.wrapped ? 2 : 1
  const lines = stack.split('\n')
  // In Chrome/Edge
  //
  // when wrapped, we want to remove 2 lines
  //  - line 1 is "Error:"
  //  - line 2 is in collectionSnapshots or documentSnapshots
  //  - line 3 is the wrapper function -> only remove if wrapped
  // So we remove lines 2 and/or 3
  //
  // when not wrapped, we want to remove 1 or 2 lines
  //  - line 1 is in collectionSnapshots or documentSnapshots
  //  - line 2 is the wrapper function -> only remove if wrapped
  //
  // In other browsers, we want to remove 2 or 1 lines, depending on whether
  // it's wrapped or not
  if (stack.match(CHROME_IE_STACK_REGEXP)) {
    lines.splice(1, linesToRemove)
  } else {
    lines.splice(0, linesToRemove)
  }
  return lines.join('\n')
}

/**
 * Replacement for .snapshots() in Dart
 * @param {boolean | undefined} options.skipUndefinedCacheResults - do not add snapshots to the stream if they are from cache and the document does not exist
 */
export function documentSnapshots<T>(
  ref: DocumentReference<T, DocumentData>,
  options?: { wrapped: boolean; skipUndefinedCacheResults?: boolean }
) {
  const controller = new StreamController<DocumentSnapshot<T, DocumentData>>()

  const stack = sanitizeStack(new Error().stack, options)

  let disposer: () => void = () => {}

  controller.onListen = () => {
    disposer = onSnapshot(
      ref,
      (snapshot) => {
        const { fromCache } = snapshot.metadata
        const skipUndefinedCacheResults =
          options?.skipUndefinedCacheResults || false

        // wait for update from server before adding document to the stream with no data
        if (skipUndefinedCacheResults && fromCache && !snapshot.exists()) return

        controller.add(snapshot)
      },
      (error) => {
        const err = new WrappedFirestoreError('Firestore error')
        err.name = 'WrappedFirestoreError'
        err.message = error.message
        err.stack = stack
        controller.addError(err)
        setTimeout(() => {
          captureException(err)
        }, 250) // give the handler 250ms to handle the error
      }
    )
  }

  controller.onCancel = () => {
    disposer()
  }

  return controller.stream
}

/**
 * Replacement for .snapshots() in Dart
 */
export function collectionSnapshots<T>(
  ref: CollectionReference<T, DocumentData> | Query<T>,
  options?: { wrapped: boolean }
) {
  const controller = new StreamController<QuerySnapshot<T, DocumentData>>()

  let disposer: () => void = () => {}
  const stack = sanitizeStack(new Error().stack, options)

  controller.onListen = () => {
    disposer = onSnapshot(
      ref,
      (snapshot) => {
        controller.add(snapshot)
      },
      (error) => {
        const err = new WrappedFirestoreError('Firestore error')
        err.name = 'WrappedFirestoreError'
        err.message = error.message
        err.stack = stack
        controller.addError(err)
        setTimeout(() => {
          captureException(err)
        }, 250) // give the handler 250ms to handle the error
      }
    )
  }

  controller.onCancel = () => {
    disposer()
  }

  return controller.stream
}

export function convertDocumentSnapshotToModel<
  T extends DocumentData,
  M extends ObservableModel<T>,
>(
  repository: FirebaseRepository,
  doc: DocumentSnapshot<T> | QueryDocumentSnapshot<T>,
  modelClass: ObservableModelClass<T, M>
) {
  const mobxDoc = {
    id: doc.id,
    ref: doc.ref,
    fromCache: doc.metadata.fromCache,
    hasPendingWrites: doc.metadata.hasPendingWrites,
    data: doc.data({
      serverTimestamps: 'estimate',
    }),
  } as MobxDocument<T>
  return new modelClass(repository, mobxDoc)
}

/**
 * Convenience function to turn a Firestore doc into a model
 */
export function modelItemStream<
  T extends DocumentData,
  M extends ObservableModel<T>,
>(
  repository: FirebaseRepository,
  ref: DocumentReference<T>,
  modelClass: ObservableModelClass<T, M>
): StreamInterface<M> {
  const result = documentSnapshots(ref, {
    wrapped: true,
    skipUndefinedCacheResults: true,
  }).map((snapshot) => {
    return convertDocumentSnapshotToModel(repository, snapshot, modelClass)
  })
  return result
}

/**
 * Convenience method to turn a list of Firestore docs into a list of models
 */
export function modelListStream<
  T extends DocumentData,
  M extends ObservableModel<T>,
>(
  repository: FirebaseRepository,
  ref: CollectionReference<T> | Query<T>,
  modelClass: ObservableModelClass<T, M>
): StreamInterface<M[]> {
  const result = collectionSnapshots(ref, { wrapped: true }).map((snapshot) => {
    const models = snapshot.docs.map((doc) => {
      return convertDocumentSnapshotToModel(repository, doc, modelClass)
    })
    return models
  })
  return result
}

export class CollectionSnapshotStreamCollector<T> {
  controller: StreamController<T[]>
  subscriptions: StreamSubscriptionActions[] = []
  streams: StreamInterface<T[]>[] = []
  streamResults = new Map<StreamInterface<T[]>, T[]>()

  constructor() {
    this.controller = new StreamController<T[]>()

    this.controller.onCancel = () => this.close()
  }

  get stream(): StreamInterface<T[]> {
    return this.controller.stream
  }

  close() {
    this.subscriptions.forEach((s) => s.cancel())
    this.controller.close()
  }

  attachStream(stream: StreamInterface<T[]>) {
    this.streams.push(stream)
    const subscription = stream.listen((values) => {
      this.streamResults.set(stream, values)
      this.flush()
    })
    this.subscriptions.push(subscription)
  }

  flush() {
    const values = this.streams
      .map((s) => this.streamResults.get(s) || [])
      .flat()
    this.controller.add(values)
  }
}
